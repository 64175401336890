<script>
import {
  h,
  toRefs,
} from 'vue';

export default {
  props: {
    tag: {
      type: String,
      default: () => 'span',
    },
  },
  setup(props, { slots }) {
    const { tag } = toRefs(props);
    return () => h(
      tag.value,
      {
        class: ['header-title'],
      },
      slots.default(),
    );
  },
};
</script>
<!--suppress CssUnusedSymbol -->
<style>

.header-title {
  display: inline;

  overflow: hidden;

  line-height: 1.1;

  white-space: nowrap;
  text-overflow: ellipsis;

}
</style>
