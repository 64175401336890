<template>
  <tr
      class="table-row"
      :class="{[`table-row--${type}`]: !!type}">
    <slot :row="row" />
  </tr>
</template>
<script>
export default {
  props: {
    row: {
      type: Object,
      default: () => null,
    },
    index: {
      type: Number,
      default: () => null,
    },
    type: {
      type: String,
      default: () => null,
    },
  },
};
</script>
<style>

.table-row {
  --table-row-background: var(--color-default-background);
  --table-row-alternate-background: var(--color-background);
  --table-current-row-background: var(--color-background);

  td, th {
    background-color: var(--table-current-row-background);
  }

  &:nth-child(2n + 1) {
    --table-current-row-background: var(--table-row-background);
    --table-current-row-alternate-background: var(--table-row-alternate-background);
  }

  &:nth-child(2n) {
    --table-current-row-background: var(--table-row-alternate-background);
    --table-current-row-alternate-background: var(--table-row-background);
  }

  &--footer:nth-child(n) {
    background-color: transparent;
  }
}
</style>
