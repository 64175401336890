<template>
  <section
      class="page-subsubsection">
    <header
        v-if="!!$slots.title || title"
        class="page-subsubsection__header">
      <div
          v-if="!!$slots.title"
          class="page-subsubsection__title">
        <slot
            name="title" />
      </div>
      <Header4
          v-else-if="title"
          class="page-subsubsection__title">
        {{ title }}
      </Header4>
    </header>

    <slot />
  </section>
</template>
<script>
import Header4 from '../typography/Header4.vue';

export default {
  components: {
    Header4,
  },
  props: {
    title: {
      type: String,
      default: () => null,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>
<style>
.page-subsubsection {
  display: grid;
  gap: var(--dimension-small);
  align-content: flex-start;

}
</style>
